import React, { Component } from "react";
import Header from "../components/auth/Header";
import Footer from "../components/auth/Footer";
import { Link } from "react-router-dom";
import { getUserDetailsAction } from "../actions/auth/authAction";

import {
  getContributionsAction,
  getAllContributionsAction,
  getAllHuddleAction,
  getVotesContributionsAction,
  getCommentsContributionsAction,
  clearUserUpdateMsg,
  getUsersListAction,
  getUserProfileAction,
} from "../actions/profileAction";
import { getCommentsListAction } from "../actions/dealCommentsAction";
import { getHuddleCommentsListAction } from "../actions/huddleCommentsAction";
import { connect } from "react-redux";
import AccountBasics from "../components/accountBasics";
import Contributions from "../components/contributions";
import UsersList from "../components/usersList";
import EditUser from "../components/editUser";
import { clearAction } from "../actions/postDealAction";
import AllDeals from "../components/allDeals";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import { avatarPath } from "../helpers/constants";
import {
  getStoresAction,
  getBrandsAction,
  getCategoriesAction,
  getTagsAction,
} from "../actions/masterAction";

import classnames from "classnames";
import StoresList from "../components/storesList";
import BrandsList from "../components/brandsList";
import CategoryList from "../components/categoryList";
import { perPage } from "../helpers/constants";
import AllCommentsList from "../components/allCommentsList";
import TagsList from "../components/tagsList";
import AllHuddle from "../components/AllHuddle";
import HuddleCommentList from "../components/HuddleCommentList";
import AllHuddleCommentsList from "../components/AllHuddleCommentsList";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "brands",
      userEdit: false,
    };

    this.handleEditClick = this.handleEditClick.bind(this);

    this.toggle = this.toggle.bind(this);
    this.catchGetToTabs = this.catchGetToTabs.bind(this);
  }

  toggle = (tab) => {
    this.props.clearUserUpdateMsg();
    this.props.clearPostDealResult();

    if (!localStorage.getItem("myToken")) {
      this.props.history.push("/login");
    }

    const { activeTab } = this.state;
    if (activeTab !== tab) {
      let isShowAll = {
        showAll: 1,
      };

      switch (tab) {
        case "profile":
          this.props.getUserDetails();

          break;
        case "contributions":
          this.props.getContributions();
          break;
        case "accountbasics":
          this.props.getUserDetails();
          break;
        case "users":
          this.props.getUsersList();
          break;
        case "allComments":
          this.props.getCommentsList();
          break;
        case "allDeals":
          // this.props.getAllContributions(0,perPage)
          break;
        case "categories":
          this.props.getCategoryList(isShowAll);
          break;
        case "brands":
          this.props.getBrandList(isShowAll);
          break;
        case "stores":
          this.props.getStoreList(isShowAll);
          break;
        case "tags":
          this.props.getTagsList(isShowAll);
          break;
        case "huddle":
          // this.props.getAllHuddle(0,10)
          break;
        case "huddleComments":
          this.props.getHuddleCommentsList();
          break;

        default:
          break;
      }

      this.setState({
        activeTab: tab,
        userEdit: false,
      });
    }
  };

  catchGetToTabs = (tab, condition) => {
    switch (condition) {
      case "all":
        this.props.getContributions();
        break;
      case "votes":
        this.props.getVotesContributions();
        break;
      case "comments":
        this.props.getCommentsContributions();
        break;

      default:
        break;
    }

    this.setState({
      activeTab: "contributions",
      userEdit: false,
    });
  };

  componentDidMount() {
    // console.log("this.props", this.props)

    if (!localStorage.getItem("myToken")) {
      this.props.history.push("/login");
    }
    window.scrollTo(0, 0);
    // this.props.getUserDetails();
    const isShowAll = {
      showAll: 1,
    };
    this.props.getBrandList(isShowAll);
    this.props.getAllContributions(0, 20);

    if (this.props.dealUpdateResult) {
      this.props.getContributions();
      this.setState({
        activeTab: "contributions",
        userEdit: false,
      });
      setTimeout(() => {
        this.props.clearPostDealResult();
      }, 3000);
    }

    if (
      this.props.location.query &&
      this.props.location.query.catch == "mydeals"
    ) {
      this.props.getContributions();
      this.setState({
        activeTab: "contributions",
        userEdit: false,
      });
    }

    setTimeout(() => {
      this.props.clearUserUpdateMsg();
    }, 3000);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.updateResult) {
      this.setState({
        userEdit: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearPostDealResult();
  }

  handleEditClick(cell) {
    this.props.getUserProfile(cell);
    this.setState({
      userEdit: true,
    });
  }

  render() {
    const {
      userInfo,
      userDetailsInfo,
      masterAddResult,
      masterUpdateResult,
      masterActionError,
    } = this.props;
    const { activeTab, userEdit } = this.state;

    return (
      <div>
        <Header {...this.props} />
        {userInfo ? (
          <section className='main profile_view'>
            {/* <div className="profile_header">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-4">
                           <div className="usernameArea">
                              {userInfo && userInfo.avatar ? <img src={`${avatarPath}/${userInfo.avatar}`} className="userAvatar" /> : <img src={require("../images/avatar-1.png")} className="userAvatar" />}

                              <span className="profileUsername">{userInfo && userInfo.firstName}</span>


                           </div>
                        </div>
                        <div className="col-md-8">
                           {this.props.updateResult ? <div className="alert alert-success alert-dismissible text-center mt-2" >
                              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>

                              {'User details updated successfully'} </div> : ''}

                           {masterAddResult && <div className="alert alert-success alert-dismissible text-center mt-2">
                              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                              {masterAddResult}
                           </div>}
                           {masterUpdateResult && <div className="alert alert-success alert-dismissible text-center mt-2">
                              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                              {masterUpdateResult}
                           </div>}
                           {masterActionError && <div className="alert alert-danger alert-dismissible text-center mt-2">
                              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                              {masterActionError}
                           </div>}
                        </div>
                     </div>
                  </div>
               </div> */}
            <div className='profile_header'>
              <div className='container'></div>
            </div>
            <div className=''>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='profileSidebar'>
                      <Nav tabs className='nav nav-pills flex-column'>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "brands",
                            })}
                            onClick={() => {
                              this.toggle("brands");
                            }}
                          >
                            Tags
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "stores",
                            })}
                            onClick={() => {
                              this.toggle("stores");
                            }}
                          >
                            Types
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "allDeals",
                            })}
                            onClick={() => {
                              this.toggle("allDeals");
                            }}
                          >
                            All Products
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <div className='col-md-9 profile_right'>
                    <div className='profileContent'>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId='brands'>
                          <div className='profileContent_headline'>
                            <h2>Tags</h2>
                          </div>
                          <BrandsList />
                        </TabPane>
                        <TabPane tabId='allDeals'>
                          <div className='profileContent_headline'>
                            <h2>Product List</h2>
                          </div>

                          {activeTab == "allDeals" ? <AllDeals /> : ""}
                        </TabPane>
                        <TabPane tabId='stores'>
                          <div className='profileContent_headline'>
                            <h2>Types</h2>
                          </div>
                          <StoresList />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section style={{ minHeight: "100vh" }}>
            <div className='spinner-border'></div>
          </section>
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.profileReducer.result,
  userDetailsInfo: state.userDetailsReducer.result,
  userLoader: state.userDetailsReducer.loader,

  updateResult: state.updateUserReducer.result,
  dealUpdateResult: state.postDealReducer.updateResult,
  userListResult: state.userListReducer.result,

  userListLoader: state.userListReducer.loader,

  masterAddResult: state.masterAddReducer.result,
  masterUpdateResult: state.masterAddReducer.updateResult,
  masterActionError: state.masterAddReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  getContributions: () => dispatch(getContributionsAction()),
  getAllContributions: (skip, limit) =>
    dispatch(getAllContributionsAction(skip, limit)),
  getAllHuddle: (skip, limit) => dispatch(getAllHuddleAction(skip, limit)),
  getVotesContributions: () => dispatch(getVotesContributionsAction()),
  getCommentsContributions: () => dispatch(getCommentsContributionsAction()),
  getUserDetails: () => dispatch(getUserDetailsAction()),
  getUserProfile: (id) => dispatch(getUserProfileAction(id)),
  getUsersList: () => dispatch(getUsersListAction()),
  getCommentsList: () => dispatch(getCommentsListAction()),
  getHuddleCommentsList: () => dispatch(getHuddleCommentsListAction()),
  clearPostDealResult: () => dispatch(clearAction()),
  getBrandList: (isShowAll) => dispatch(getBrandsAction(isShowAll)),
  clearUserUpdateMsg: () => dispatch(clearUserUpdateMsg()),

  getStoreList: (isShowAll) => dispatch(getStoresAction(isShowAll)),
  getTagsList: (isShowAll) => dispatch(getTagsAction(isShowAll)),

  getCategoryList: (isShowAll) => dispatch(getCategoriesAction(isShowAll)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserAction,
  getUserDetailsAction,
} from "../../actions/auth/authAction";
import { getPopularCatsAction } from "../../actions/masterAction";
import $ from "jquery";
import { Collapse, Col } from "reactstrap";
import HeaderSearchForm from "../headerSearchForm";
import { avatarPath } from "../../helpers/constants";

class Header extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
      isOpen2: false,
    };
    this.logout = this.logout.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  toggle2 = () => {
    this.setState({
      isOpen2: !this.state.isOpen2,
    });
  };

  componentDidMount() {
    //set browser session
    if (!sessionStorage.getItem("easySession")) {
      sessionStorage.setItem("easySession", new Date().getTime());
    }
    // this.props.getPopularCats();
    this.props.getUser();
    this.props.getUserDetails();

    //jquery
    $(".dropdown-toggle").click(function () {
      $(this).next(".dropdown-menu").slideToggle();
    });
  }

  logout() {
    localStorage.clear();
    window.location.href = "/";
  }

  render() {
    const cat = this.props.match.params.cat;
    const { isOpen, isOpen2 } = this.state;
    const { userInfo } = this.props;
    return (
      <header>
        <div className='header-top'>
          <div className='container-fluid'>
            <div className='row align-items-center'>
              <div className='col-md-1 col-sm-2'>
                <div className='logo'>
                  <a href='/'>
                    <img src={require("../../images/naifa-white-logo.png")} />
                  </a>
                </div>
              </div>
              {/* <div className="col-md-6 col-sm-5"> 
              <div className="searchbar mtb-5">
                <form className="mb-0">
                  <input type="text" className="form-control" name="q" value="" placeholder="Search here" />
                  <span className="icon-search">
                          <input type="submit" value="" className="submit-icon"/>
                        </span>
                </form>
              </div>
            </div> */}
              <HeaderSearchForm {...this.props} />
              <div className='col-md-5 col-sm-12'>
                <div className='user_profile'>
                  {localStorage.getItem("myStatus") === "logged_in" && (
                    <div className='user_container  text-right'>
                      <div className='user'>
                        {userInfo && userInfo.avatar ? (
                          <img
                            src={`${avatarPath}/${userInfo.avatar}`}
                            className='userAvatar'
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className='user_name' id='mainmenu'>
                        <div className='dropdown'>
                          <a
                            className='dropdown-toggle'
                            data-toggle='dropdown'
                            href='#'
                          >
                            {userInfo && userInfo.email}
                          </a>
                          <ul
                            className='dropdown-menu dropdown-menu-right'
                            role='menu'
                            aria-labelledby='dLabel'
                          >
                            {
                              <li>
                                <Link
                                  to={`/profile/${
                                    this.props.userInfo &&
                                    this.props.userInfo.id
                                  }`}
                                >
                                  Manage data
                                </Link>
                              </li>
                            }

                            {/* <li className="dropdown-divider"></li> */}

                            {/* <li><Link  to={{ pathname: `/profile/${this.props.userInfo&&this.props.userInfo.id}`, query: { catch: 'mydeals' } }} >My Deals</Link></li> */}
                            {/* <li><Link to={'/deal-alerts'}>My Deal Alerts</Link></li>
                            <li className="dropdown-divider"></li> */}

                            {/* <li><Link to={'/mails'}>Inbox</Link></li>
                            <li className="dropdown-divider"></li> */}
                            {/* <li className="dropdown-divider"></li>
                            <li  className="dropdown"><a href="#" className="dropdown-toggle1" onClick = {this.toggle2}>Saved Items</a>

                            <Collapse isOpen={isOpen2}>
                            <ul  >      
                                <li><a href="#">Subscribed Threads</a></li>
                                <li><a href="#">Ignored Threads</a></li>
                                <li><a href="#">Saved Searches</a></li>
                                <li><a href="#">Thread Stickies</a></li>
                                <li><a href="#">Reminders</a></li>
                              </ul>
                            </Collapse>
                            
                            </li> */}
                            {/* <li className="dropdown-divider"></li>
                            <li><a href="#">Notifications</a></li>
                            <li className="dropdown-divider"></li>
                            <li><a href="#">Settings & Options</a></li>
                            <li className="dropdown-divider"></li> */}
                            <li>
                              <Link to={"/post-deal"}>Create Product</Link>
                            </li>
                            {/* <li><Link to={'/post-deal'}>Create Tags</Link></li> */}
                            <li>
                              <a href='#' onClick={this.logout}>
                                Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {localStorage.getItem("myStatus") === "logged_in" ? (
                    <div className='login-btn-container text-right'>
                      {/* <Link to="/post-deal" className="btn btn-success login-btn">Post Deal</Link>
                      <a href="/huddle" className="btn btn-success login-btn  ml-2">Huddle</a> */}
                    </div>
                  ) : (
                    <div className='login-btn-container text-right'>
                      {/* <Link to="/post-deal" className="btn btn-success login-btn">Post Deal</Link> */}
                      <a
                        target='_blank'
                        href='https://community.naifa.org/login.aspx'
                        // href='https://members-naifa.site-ym.com/admin/Login.aspx?redir=/admin/'
                        className='btn btn-primary login-btn mr-10'
                      >
                        Log In
                      </a>
                      {/*<Link to="/signup" className="btn btn-primary sign-up-btn">Sign Up</Link>
                        <Link to="/huddle" className="btn btn-success login-btn ml-2">Huddle</Link> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='menu'>
          <div className='container-fluid'>
            <nav className='navbar navbar-expand-sm navbar-dark '>
              <button
                className='navbar-toggler'
                type='button'
                data-toggle='collapse'
                data-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
              </button>

              <div
                className='collapse navbar-collapse in'
                id='navbarSupportedContent'
              >
                <ul className='nav navbar-nav mr-auto'>
                  {/* <li className="nav-item">
                    <a href= "/" className={cat ==''?"nav-link active":'nav-link'} >BACK</a>
                  </li> */}
                  <li className='nav-item'>
                    <a
                      href='/deals/learning'
                      className={
                        cat == "electronics" ? "nav-link active" : "nav-link"
                      }
                    >
                      LEARNING
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a href= "/deals/ifapac" className={cat =='ifapac'?"nav-link active":'nav-link'} >IFAPAC</a>
                  </li>
                  <li className="nav-item">
                    <a href= "/deals/apparel" className={cat =='apparel'?"nav-link active":'nav-link'} >APPAREL </a>
                  </li> */}
                  <li className='nav-item d-none'>
                    <a
                      href='/deals/events'
                      className={
                        cat == "events" ? "nav-link active" : "nav-link"
                      }
                    >
                      EVENTS
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a href= "/deals/misc" className={cat =='misc'?"nav-link active":'nav-link'} >MISC</a>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.profileReducer.result,
  userDetailsInfo: state.userDetailsReducer.result,
});
const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUserAction()),
  getPopularCats: () => dispatch(getPopularCatsAction()),
  getUserDetails: () => dispatch(getUserDetailsAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);

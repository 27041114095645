import React, { Component } from "react";
import Header from "../components/auth/Header";
import Footer from "../components/auth/Footer";
import queryString from "query-string";
import { connect } from "react-redux";
import { searchDealAction } from "../actions/dealsAction";
import { imagePath, perPage } from "../helpers/constants";
import { Link } from "react-router-dom";
import PaginationComponent from "../components/paginationComponent";
import moment from "moment";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtxt: "",
      sortby: "",
      page: 1,
    };

    this.getSearchText = this.getSearchText.bind(this);
    this.changeSortBy = this.changeSortBy.bind(this);
    this.doPaginate = this.doPaginate.bind(this);
    this.convertUTCToLocal = this.convertUTCToLocal.bind(this);
  }

  convertUTCToLocal = (utcDt, utcDtFormat) => {
    var toDt = moment.utc(utcDt, utcDtFormat).toDate();
    return moment(toDt).format("DD-MM-YYYY hh:mm:ss A");
  };

  getSearchText = (txt) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("deal", txt);
    this.props.history.push(
      window.location.pathname + "?" + currentUrlParams.toString()
    );
    this.setState({
      ...this.state,
      searchtxt: txt,
      page: 1,
    });

    let searchParams = {
      searchtxt: txt,
      sortby: this.state.sortby,
    };
    this.props.searchDeal(searchParams);
  };
  changeSortBy = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      page: 1,
    });

    let searchParams = {
      searchtxt: this.state.searchtxt,
      sortby: e.target.value,
    };
    this.props.searchDeal(searchParams);
  };

  componentDidMount() {
    const { deal } = queryString.parse(this.props.location.search);
    this.setState({
      searchtxt: deal,
      sortby: "newest",
      page: 1,
    });

    let searchParams = {
      searchtxt: deal,
      sortby: "newest",
      page: 1,
    };
    this.props.searchDeal(searchParams);
  }

  doPaginate = (page) => {
    let searchParams = {
      searchtxt: this.state.searchtxt,
      sortby: this.state.sortby,
      page: page,
    };

    this.setState({
      ...this.state,
      page: page,
    });
    this.props.searchDeal(searchParams);
  };
  componentWillReceiveProps(nextProps) {}
  render() {
    const { loader, count } = this.props;
    return (
      <div>
        <Header {...this.props} SendSearchText={this.getSearchText} />
        <section className='main'>
          <div className='container-fluid'>
            <div className='searchresult'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='search_info'>
                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='search_results_num'>
                          <span>{!loader && ""}</span> Results found matching{" "}
                          <strong>{this.state.searchtxt}</strong>
                          <span>
                            <a
                              href='#'
                              className
                              tabIndex={0}
                              data-toggle='popover'
                              data-trigger='click'
                              data-placement='bottom'
                              data-popover-content='#searchresult_info'
                            >
                              {/* <i className="fa fa-info-circle" aria-hidden="true" /> */}
                            </a>
                            <div id='searchresult_info' className='hidden'>
                              <div className='popover-body'>
                                Offers in this section include some of our best
                                recent deals and may be sponsored by the
                                merchant.{" "}
                                <strong>
                                  <a href='#' target='_blank'>
                                    Learn More
                                  </a>
                                </strong>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr className='gridSeparator' />
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='tab-content'>
                          <div id='Mixed' className='tab-pane active'>
                            {this.renderDeals()}
                          </div>
                          <div id='FrontpageDeals' className=' tab-pane fade'>
                            <br />
                            bbb
                          </div>
                          <div id='PopDeals' className=' tab-pane fade'>
                            <br />
                            ccc
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }

  renderDeals() {
    const { count, loader } = this.props;

    return (
      <div className='row'>
        {/* <div className="col-md-12">
            <div className="notifBar">
              We also found a user named "television", do you want to see <a href="/forums/member.php?u=4770162">television's profile</a> or <a href="/newsearch.php?showposts=1&searchuserid=4770162">view television's posts</a>?
            </div>
          </div> */}
        <div className='col-md-12'>
          <div className='search_results_table table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th className='deal_img'></th>
                  <th className='deal_info'>Title</th>
                  <th className='deal_price'>Member Price</th>
                  <th className='deal_price'>Non-Member Price</th>
                  {/* <th className="deal_rating">Rating</th> */}
                  {/* <th className="deal_rating">Activity</th> */}
                  <th className='deal_sort'>
                    <select
                      name='sortby'
                      id='sorter'
                      onChange={this.changeSortBy}
                    >
                      <option
                        selected={this.state.sortby == "newset"}
                        value='newest'
                      >
                        Sort By Newest
                      </option>
                      <option
                        selected={this.state.sortby == "oldest"}
                        value='oldest'
                      >
                        Sort By Oldest
                      </option>
                      {/* <option value="relevance">Sort By Relevance</option> */}
                      {/* <option value="popular">Sort By Popualarity</option> */}
                      <option
                        selected={this.state.sortby == "lowest_price"}
                        value='lowest_price'
                      >
                        Sort by Price (Lowest)
                      </option>
                      <option
                        selected={this.state.sortby == "highest_price"}
                        value='highest_price'
                      >
                        Sort by Price (Highest)
                      </option>
                      {/* <option value="hotness">Sort By Hotness</option> */}
                    </select>
                  </th>

                  <th className='deal_rating'></th>
                </tr>
              </thead>
              {!loader ? (
                <tbody>{this.renderResultRows()}</tbody>
              ) : (
                <tbody>
                  {" "}
                  <div className='spinner-border'></div>
                </tbody>
              )}
              <tfoot>
                <tr>
                  <td colSpan='5'>
                    {!loader && (
                      <PaginationComponent
                        page={this.state.page}
                        perPage={perPage}
                        totalCount={count}
                        handlePageChange={this.doPaginate}
                      />
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    );
  }

  renderResultRows() {
    const { deals } = this.props;

    return (
      deals &&
      deals.map((deal, idx) => {
        return (
          <tr className='resultRow'>
            <td className='deal_img'>
              <Link to={`/deal/${deal.id}`}>
                {deal.del_image_primary ? (
                  <img
                    className='lazyimg'
                    alt={deal.del_image_primary}
                    src={`${imagePath}/${deal.id}/${deal.del_image_primary}`}
                  />
                ) : (
                  <img
                    className='lazyimg'
                    alt='no image'
                    src={require("../images/noimage.jpg")}
                  />
                )}
              </Link>
              {/* <a href="#">
              <img className="lazyimg" src={deal.del_image_primary} style={{display: 'inline'}} />
            </a> */}
            </td>
            <td className='mainDealInfo'>
              <div className='dealWrapper'>
                <Link
                  to={`/deal/${deal.id}`}
                  title={deal.del_title}
                  className='dealTitle'
                >
                  {deal.del_title}
                </Link>
                <div className='dealInfo'>
                  <div>
                    {/* <span className="featuredSponsor"><span><i className="fa fa-arrow-circle-up" aria-hidden="true" /></span><strong>Featured</strong></span> */}
                    Posted on {this.convertUTCToLocal(deal.created_at)}
                  </div>
                </div>
              </div>
            </td>
            <td className='deal_price'>
              <span className='d-block d-sm-none'>{"Member Price: "}</span>
              <span className='price'>
                {" "}
                {`$${deal.del_price.toFixed(2)}`}
                <br />
                <span class='deal_old_price search-strike-price'>
                  {deal.stricken_price && deal.del_hide_stricken_price === 0
                    ? `$${deal.stricken_price}`
                    : ""}
                </span>
              </span>
            </td>
            <td className='deal_price'>
              {deal.del_price2 ? (
                <>
                  <span className='d-inline d-sm-none'>
                    {"Non-Member Price: "}
                  </span>
                  <span className='price search-non-member-price'>
                    {`$${deal.del_price2.toFixed(2)}`}
                    <br />
                    <span class='deal_old_price search-strike-price'></span>
                  </span>
                </>
              ) : (
                ""
              )}
            </td>

            {/* <td className="deal_activity">
            <div className="text" title={`${deal.del_count_view} views`}><i className="fa fa-eye" aria-hidden="true" />{deal.del_count_view} <span className="textLabel">Views</span></div>

            <div className="text" title={`${(deal.del_count_like - deal.del_count_dislike)} Likes`}><i className="fa fa-thumbs-up" aria-hidden="true" /> {(deal.del_count_like - deal.del_count_dislike)} <span className="textLabel">Likes</span></div>
            <div className="text" title={`${deal.del_count_comments} Comments`}><i className="fa fa-commenting" aria-hidden="true" /> {deal.del_count_comments} <span className="textLabel">Comments</span></div>

           
          </td> */}
            <td className='deal_sort'>
              Posted on
              <br />
              {/* <div>
              <a href="#" className="username">sendmedadeals<i className="fa fa-arrow-circle-right" aria-hidden="true" /></a>
            </div> */}
              {this.convertUTCToLocal(deal.created_at)}
              {/* 1 hour ago 9:54 PM */}
            </td>
            <td className='deal_rating'>
              <a target='_blank' href={deal.del_url} class='btn'>
                Buy Now
              </a>
            </td>
            {/* <td className="deal_sort">
            New reply by<br />
            <div>
              <a href="#" className="username">sendmedadeals<i className="fa fa-arrow-circle-right" aria-hidden="true" /></a>
            </div>
            1 hour ago 9:54 PM
          </td> */}
          </tr>
        );
      })
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.profileReducer.result,
  loader: state.searchDealsReducer.loader,
  deals: state.searchDealsReducer.result,
  error: state.searchDealsReducer.error,
  count: state.searchDealsReducer.count,
});
const mapDispatchToProps = (dispatch) => ({
  searchDeal: (data) => dispatch(searchDealAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Search);

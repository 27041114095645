import React, { Component } from "react";
import Header from "../components/auth/Header";
import Footer from "../components/auth/Footer";
import DealProgress from "./dealProgress";
import PostDealForm from "../components/postDealForm";
import { StickyContainer, Sticky } from "react-sticky";

class PostDeal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header {...this.props} />
        <section className='main'>
          <div className='container-fluid'>
            <div className='container dealPosterContainer'>
              <StickyContainer>
                <div className='row'>
                  <div className='post_deal col-md-12'>
                    <div className='postdeals_header'>
                      <h2>Create New Product</h2>
                      {/* <p>
                        Help others save money by finding the lowest price, best
                        products, and hot coupons.
                      </p> */}
                    </div>
                    <PostDealForm
                      editMode={false}
                      dealData={null}
                      dealCategories={null}
                      dealBrands={null}
                      dealStores={null}
                      dealImages={null}
                      {...this.props}
                      key='add'
                    />
                    <div className='deal_note'>
                      <p>
                        <strong>Helpful Product creating Tips : </strong>
                        Do a quick search on NAIFA to make sure this is not a
                        duplicate.
                      </p>
                    </div>
                  </div>
                  {/* <DealProgress /> */}
                </div>
              </StickyContainer>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default PostDeal;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { clearUserUpdateMsg } from "../actions/profileAction";
import {
  addBrandsAction,
  clearMasterAddMsg,
  updateBrandsAction,
  deleteMasterAction,
} from "../actions/masterAction";
import MasterEditPopup from "./masterEditPopup";

import MasterTrashPopup from "./masterTrashPopup";
import AddBrand from "./addBrand";

const { SearchBar } = Search;

class BrandsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: "",
      trashId: "",
      isOpentrash: false,
      isOpen: false,
      currentPage: 1,
      perPage: 10,
    };

    this.formatAction = this.formatAction.bind(this);
    this.formatSlno = this.formatSlno.bind(this);
    this.formatStatus = this.formatStatus.bind(this);
    this.saveStore = this.saveStore.bind(this);
    this.updateStore = this.updateStore.bind(this);
    this.trashStore = this.trashStore.bind(this);
    this.editStore = this.editStore.bind(this);
    this.deleteStore = this.deleteStore.bind(this);

    this.closeModal = this.closeModal.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSizePerPageChange = this.onSizePerPageChange.bind(this);
  }

  onPageChange = (page, perPage) => {
    this.setState({
      currentPage: page,
      perPage: perPage,
    });
  };
  onSizePerPageChange = (perPage, page) => {
    this.setState({
      currentPage: page,
      perPage: perPage,
    });
  };

  formatAction = (row) => {
    let sendData = {
      name: row.brd_name,
      description: row.brd_desc,
      id: row.id,
      status: row.brd_active,
    };

    return (
      <span>
        {" "}
        <a
          title='edit'
          onClick={(e) => {
            this.editStore(e, sendData);
          }}
          href='#'
        >
          {" "}
          <i className='fa fa-pencil'></i>
        </a>{" "}
        &nbsp;&nbsp;
        {row.brd_active ? (
          <a
            title='Suspend'
            onClick={(e) => {
              this.deleteStore(e, row.id);
            }}
            href='#'
          >
            {" "}
            <i className='fa fa-ban'></i>
          </a>
        ) : (
          ""
        )}
      </span>
    );
  };
  formatSlno = (cell, row, rowIndex) => {
    const { currentPage, perPage } = this.state;

    let slNo = rowIndex + 1;

    slNo = currentPage * perPage + slNo - perPage;

    return slNo;
  };
  formatStatus = (cell) => {
    if (cell === 0) {
      return "Suspended";
    } else if (cell === 1) {
      return "Active";
    }
  };

  saveStore = (formData) => {
    this.props.addBrand(formData);
  };
  updateStore = (formData) => {
    this.props.updateBrand(formData);

    setTimeout(() => {
      this.setState({
        editData: {},
        isOpen: false,
      });
    }, 1000);
  };

  trashStore = (id) => {
    this.props.deleteBrandFn(id, "brd");

    setTimeout(() => {
      this.setState({
        trashId: "",
        isOpentrash: false,
      });
    }, 1000);
  };
  editStore = (e, data) => {
    e.preventDefault();
    this.setState({
      editData: data,
      isOpen: true,
    });
  };
  deleteStore = (e, id) => {
    e.preventDefault();
    this.setState({
      trashId: id,
      isOpentrash: true,
    });
  };
  closeModal = () => {
    this.setState({
      editData: {},
      isOpen: false,
      trashId: "",
      isOpentrash: false,
    });
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}
  componentWillUnmount() {
    this.props.clearMasterAddMsg();
  }
  render() {
    const columns = [
      {
        dataField: "id",
        text: "#",
        formatter: this.formatSlno,
      },
      {
        dataField: "brd_name",
        text: "Tags",
      },
      {
        dataField: "brd_desc",
        text: "Description",
      },
      {
        dataField: "brd_active",
        text: "Status",
        formatter: (cell) => this.formatStatus(cell),
      },

      {
        dataField: "id",
        text: "Action",
        formatter: (cell, row) => this.formatAction(row),
      },
    ];

    const { brands } = this.props;
    const { editData, isOpen, isOpentrash, trashId } = this.state;
    const options = {
      onPageChange: this.onPageChange,
      onSizePerPageChange: this.onSizePerPageChange,
    };
    return (
      <div>
        <AddBrand saveStore={this.saveStore} />
        {brands && (
          <ToolkitProvider
            bootstrap4
            keyField='id'
            data={brands}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <hr />
                <BootstrapTable
                  classes='table-responsive'
                  wrapperClasses='table-responsive'
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
              </div>
            )}
          </ToolkitProvider>
        )}
        <MasterEditPopup
          isOpen={isOpen}
          editData={editData}
          closeModal={this.closeModal}
          onUpdated={this.updateStore}
          key={editData.id}
        />
        <MasterTrashPopup
          isOpentrash={isOpentrash}
          trashId={trashId}
          closeModal={this.closeModal}
          onDelete={this.trashStore}
          key={trashId}
        />{" "}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  brands: state.brandsMasterReducer.brands,
});
const mapDispatchToProps = (dispatch) => ({
  clearMasterAddMsg: () => dispatch(clearMasterAddMsg()),
  addBrand: (formData) => dispatch(addBrandsAction(formData)),
  updateBrand: (formData) => dispatch(updateBrandsAction(formData)),
  deleteBrandFn: (id, type) => dispatch(deleteMasterAction(id, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BrandsList);

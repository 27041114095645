/*
 src/reducers/rootReducer.js
*/
import { combineReducers } from 'redux';
import simpleReducer from './simpleReducer';
import postDealReducer from './postDealReducer';
import dealProgressReducer from './dealProgressReducer';
import brandsMasterReducer from './brandsMasterReducer';
import categoryMasterReducer from './categoryMasterReducer';
import storesMasterReducer from './storesMasterReducer';
import dealsReducer from './dealsReducer';
import searchDealsReducer from './searchDealsReducer';
import dealDetailsReducer from './dealDetailsReducer';
import authReducer from './auth/authReducer';
import tokenAuthReducer from './auth/tokenAuthReducer';
import pwdTokenAuthReducer from './auth/pwdTokenAuthReducer';
import profileReducer from './profileReducer';
import likeReducer from './likeReducer';
import dealUrlImagesReducer from './dealUrlImagesReducer';
import myDealsReducer from './myDealsReducer';
import allDealsReducer from './allDealsReducer';
import postDealAlertReducer from './postDealAlertReducer';
import dealAlertReducer from './dealAlertReducer';
import dealAlertPauseReducer from './dealAlertPauseReducer';
import dealCommentsReducer from './dealCommentsReducer';
import popularDealsReducer from './popularDealsReducer';
import updateUserReducer from './updateUserReducer';
import userDetailsReducer from './userDetailsReducer';
import userListReducer from './userListReducer';
import passwordReducer from './passwordReducer';
import changePwdReducer from './changePwdReducer';
import commentsListReducer from './commentsListReducer';
import unflagCommentsReducer from './unflagCommentsReducer';
import masterAddReducer from './masterAddReducer';
import popularCategoryReducer from './popularCategoryReducer';
import tagsMasterReducer from './tagsMasterReducer';
import mailListReducer from './mailListReducer';
import mailActionReducer from './mailActionReducer';
import emailListReducer from './emailListReducer';
import unsubscribeReducer from './unsubscribeReducer';
import reputationBreakUpReducer from './reputationBreakUpReducer';
import huddleListReducer from './huddleListReducer';
import huddleDetailsReducer from './huddleDetailsReducer';
import huddleLikeReducer from './huddleLikeReducer';
import huddleCommentsReducer from './huddleCommentsReducer';
import allHuddleReducer from './allHuddleReducer';
import deleteReducer from './deleteReducer';


export default combineReducers({
    simpleReducer,
    authReducer,
    postDealReducer,
    brandsMasterReducer,
    categoryMasterReducer,
    storesMasterReducer,
    dealsReducer,
    dealProgressReducer,
    profileReducer,
    likeReducer,
    dealDetailsReducer,
    dealUrlImagesReducer,
    tokenAuthReducer,
    searchDealsReducer,
    myDealsReducer,
    postDealAlertReducer,
    dealAlertReducer,
    dealAlertPauseReducer,
    dealCommentsReducer,
    popularDealsReducer,
    updateUserReducer,
    userDetailsReducer,
    userListReducer,
    passwordReducer,
    pwdTokenAuthReducer,
    changePwdReducer,
    allDealsReducer,
    commentsListReducer,
    unflagCommentsReducer,
    masterAddReducer,
    popularCategoryReducer,
    tagsMasterReducer,
    mailListReducer,
    mailActionReducer,
    emailListReducer,
    unsubscribeReducer,
    reputationBreakUpReducer,
    huddleListReducer,
    huddleDetailsReducer,
    huddleLikeReducer,
    huddleCommentsReducer,
    allHuddleReducer,
    deleteReducer

});
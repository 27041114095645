import React, { Component } from "react";

import { connect } from "react-redux";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { StickyContainer, Sticky } from "react-sticky";

const theme = {
  default: {
    trailColor: "#EEEEEE",
  },
  mid: {
    trailColor: "#EEEEEE",
    color: "#72BC19",
  },

  success: {
    trailColor: "#EEEEEE",
    color: "#519f52",
  },
};
let renderCount = 0;
class DealProgress extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    const {
      urlStatus,
      titleStatus,
      priceStatus,
      descriptionStatus,
      categoryStatus,
      brandStatus,
      storeStatus,
      labelStatus,
      imageStatus,
      currentStatus,
    } = this.props;

    let titleScroe = 20;
    let urlScroe = 10;
    let descScroe = 10;
    let catScroe = 10;
    let brandScroe = 10;
    let storeScroe = 10;
    let labelScroe = 10;
    let imageScroe = 10;
    let priceScroe = 10;

    let totalScore = 0;

    if (urlStatus == "complete" || urlStatus == "hidden")
      totalScore = totalScore + urlScroe;
    if (titleStatus == "complete") totalScore = totalScore + titleScroe;
    if (priceStatus == "complete") totalScore = totalScore + priceScroe;
    if (descriptionStatus == "complete") totalScore = totalScore + descScroe;
    if (categoryStatus == "complete") totalScore = totalScore + catScroe;
    if (brandStatus == "complete") totalScore = totalScore + brandScroe;
    if (storeStatus == "complete") totalScore = totalScore + storeScroe;
    if (labelStatus == "complete") totalScore = totalScore + labelScroe;
    if (imageStatus == "complete") totalScore = totalScore + imageScroe;

    return (
      <div className='progress_sidebar  col-md-4' align='center'>
        <Sticky>
          {({ style }) => (
            <div style={style}>
              <div className='progress_header'>
                <h3>Let's Get Started</h3>
                <p>
                  Be as detailed as possible when filling out the form to
                  increase the chance of your product being seen by others.
                </p>
              </div>

              <Progress
                theme={theme}
                percent={totalScore}
                status={
                  totalScore == 100 ? "success" : totalScore == 0 ? "" : "mid"
                }
              />

              <div className='status_indicator'>
                <ol id='statusIndicator'>
                  <li
                    data-progress-type='url'
                    className={
                      currentStatus === "dealUrl"
                        ? "current url " + urlStatus
                        : urlStatus + " url"
                    }
                  >
                    <strong>Perma Link</strong>
                    <div className='statusPopup'>
                      First, enter a product URL and we'll try to pre-fill as
                      much information as possible.
                    </div>
                  </li>
                  <li
                    data-progress-type='title'
                    className={
                      currentStatus === "dealTitle"
                        ? "current title " + titleStatus
                        : titleStatus + " title"
                    }
                  >
                    <strong>Product Name</strong>
                    <div className='statusPopup'>
                      Add a name for your product including the price.
                    </div>
                  </li>
                  <li
                    data-progress-type='price'
                    className={
                      currentStatus === "dealPrice"
                        ? "current price " + priceStatus
                        : priceStatus + " price"
                    }
                  >
                    <strong>Price</strong>
                    <div className='statusPopup'>
                      Enter the final price for your product.
                    </div>
                  </li>
                  <li
                    data-progress-type='description'
                    className={
                      currentStatus === "dealDescription"
                        ? "current description " + descriptionStatus
                        : descriptionStatus + " description"
                    }
                  >
                    <strong>Description</strong>
                    <div className='statusPopup'>
                      Enter product info, such as coupons/rebates. Include
                      specific steps if applicable.
                    </div>
                  </li>
                  <li
                    data-progress-type='category'
                    className={
                      currentStatus === "dealCategories"
                        ? "current category " + categoryStatus
                        : categoryStatus + " category"
                    }
                  >
                    <strong>Categories</strong>
                    <div className='statusPopup'>
                      Add one or more relevant categories to your product.
                    </div>
                  </li>
                  <li
                    data-progress-type='store'
                    className={
                      currentStatus === "dealStores"
                        ? "current store " + storeStatus
                        : storeStatus + " store"
                    }
                  >
                    <strong>Type</strong>
                    <div className='statusPopup'>
                      Add your product type (i.e. whether it is a Career
                      posting, Event or other types).
                    </div>
                  </li>
                  <li
                    data-progress-type='brands'
                    className={
                      currentStatus === "dealBrands"
                        ? "current brands " + brandStatus
                        : brandStatus + " brands"
                    }
                  >
                    <strong>Tags</strong>
                    <div className='statusPopup'>
                      Add one or more tags to your product.
                    </div>
                  </li>
                  <li
                    data-progress-type='labels'
                    className={
                      currentStatus === "dealTags"
                        ? "current labels " + labelStatus
                        : labelStatus + " labels"
                    }
                  >
                    <strong>Labeling</strong>
                    <div className='statusPopup'>
                      Add one or more labels to your product.
                    </div>
                  </li>
                  <li
                    data-progress-type='images'
                    className={
                      currentStatus === "dealImages"
                        ? "current images " + imageStatus
                        : imageStatus + " images"
                    }
                  >
                    <strong>Images</strong>
                    <div className='statusPopup'>
                      Upload images/attachments to your product.
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          )}
        </Sticky>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    urlStatus: state.dealProgressReducer.urlStatus,
    titleStatus: state.dealProgressReducer.titleStatus,
    priceStatus: state.dealProgressReducer.priceStatus,
    descriptionStatus: state.dealProgressReducer.descriptionStatus,
    categoryStatus: state.dealProgressReducer.categoryStatus,
    brandStatus: state.dealProgressReducer.brandStatus,
    storeStatus: state.dealProgressReducer.storeStatus,
    labelStatus: state.dealProgressReducer.labelStatus,
    imageStatus: state.dealProgressReducer.imageStatus,
    currentStatus: state.dealProgressReducer.current,
  };
};

export default connect(mapStateToProps, {})(DealProgress);

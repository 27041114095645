import React, { Component } from "react";
import "./App.css";
import "./css/style.css";
import "./css/custom.scss";
import Home from "./components/home";
import Login from "./components/auth/Login";
import history from "./helpers/history";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import PostDeal from "./containers/postDeal";
import DealDetails from "./containers/dealDetails";

import Search from "./containers/Search";
import EditDeal from "./containers/editDeal";
import AuthenticateReg from "./containers/authenticateReg";
import Unsubscribe from "./containers/Unsubscribe";
import Profile from "./containers/profile";
//import Termsofuse from './containers/termsofuse';
//import PrivacyPolicy from './containers/privacyPolicy';
import PrivateRoute from "./components/auth/privateRoute";
import DealAlerts from "./containers/dealAlerts";
import ForgotPassword from "./components/auth/forgotPassword";
import ForgotPasswordVerification from "./containers/forgotPasswordVerification";
import ResetPassword from "./components/auth/resetPassword";

import PopularCategory from "./components/popularCategory";
import Mails from "./containers/Mails";

import NotFound from "./containers/NotFound";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInStatus: "NOT_LOGGED_IN",
      user: {},
    };
  }

  componentWillMount() {
    console.log = console.warn = console.error = () => {};
    // console.error('Something bad happened.');
  }

  render() {
    return (
      <BrowserRouter history={history}>
        <Switch>
          <Route exact path={"/"} render={(props) => <Home {...props} />} />
          <Route
            exact
            path={"/login"}
            render={(props) => <Login {...props} />}
          />

          <Route
            exact
            path={"/forgot-password"}
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path={"/reset-password/:token"}
            render={(props) => <ResetPassword {...props} />}
          />
          <PrivateRoute exact path='/post-deal' component={PostDeal} />

          <Route
            exact
            path={"/deal/:id"}
            render={(props) => <DealDetails {...props} />}
          />
          <Route
            exact
            path={"/deals/:cat"}
            render={(props) => <PopularCategory {...props} />}
          />

          <PrivateRoute exact path='/edit-deal/:id' component={EditDeal} />

          <Route
            exact
            path={"/search"}
            render={(props) => <Search {...props} />}
          />
          <Route
            exact
            path={"/authenticate/:token"}
            render={(props) => <AuthenticateReg {...props} />}
          />
          <Route
            exact
            path={"/unsubscribe/:token"}
            render={(props) => <Unsubscribe {...props} />}
          />
          <Route
            exact
            path={"/passwordReset/:token"}
            render={(props) => <ForgotPasswordVerification {...props} />}
          />
          <PrivateRoute exact path='/profile/:id' component={Profile} />
          <PrivateRoute exact path='/deal-alerts' component={DealAlerts} />
          <PrivateRoute exact path='/mails' component={Mails} />
          {/* <Route
                  exact path={'/terms-of-service'}
                  render={props => ( <Termsofuse {...props}/> )}
               />
               <Route
                  exact path={'/privacy-policy'}
                  render={props => (<PrivacyPolicy {...props}/>)}
               /> */}

          <Route path='*' render={(props) => <NotFound {...props} />} />
        </Switch>
      </BrowserRouter>
    );
  }
}
export default App;

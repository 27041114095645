import React, { Component } from "react";
import Header from "../components/auth/Header";
import Footer from "../components/auth/Footer";
import DealProgress from "./dealProgress";
import PostDealForm from "../components/postDealForm";
import { connect } from "react-redux";
import { getDealViewAction } from "../actions/dealsAction";
import { clearProgressAction } from "../actions/postDealAction";
import { StickyContainer, Sticky } from "react-sticky";
import { dealEditablePeriod } from "../helpers/constants";

import { imagePath } from "../helpers/constants";

class EditDeal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getDealView(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.clearInputProgress();
  }

  render() {
    const {
      loader,
      result,
      dealStores,
      dealCategories,
      dealBrands,
      dealTags,
      dealImages,
      dealIUser,
      userInfo,
    } = this.props;

    //check for edit period
    let shwEditForUser = false;
    let shwEditForAdmin = false;
    let d1;
    let d2;
    if (result && userInfo && dealIUser) {
      d1 = new Date();
      d2 = new Date(result.created_at);
      d2.setDate(d2.getDate() + dealEditablePeriod);
      if (d1.getTime() <= d2.getTime() && userInfo.id === dealIUser.id) {
        shwEditForUser = true;
      }
      if (userInfo.role === 2 || userInfo.role === 3) {
        shwEditForAdmin = true;
      }
    }
    //check for edit period ends here
    return (
      <div>
        <Header {...this.props} />

        {!loader && shwEditForUser ? (
          <section className='main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-10 col-sm-12 dealPosterContainer'>
                  {!loader && result ? (
                    <StickyContainer>
                      <div className='row'>
                        <div className='post_deal col-md-12'>
                          <div className='postdeals_header'>
                            <h2>Update Product</h2>
                            {/* <p>
                              Help others save money by finding the lowest
                              price, best Products.
                            </p> */}
                          </div>
                          <PostDealForm
                            editMode={true}
                            dealData={result}
                            dealCategories={dealCategories}
                            dealBrands={dealBrands}
                            dealStores={dealStores}
                            dealTags={dealTags}
                            {...this.props}
                            dealImages={dealImages}
                            key='add'
                          />
                          <div className='deal_note'>
                            <p>
                              <strong>Helpful Product creating Tips : </strong>
                              Do a quick search on NAIFA to make sure this is
                              not a duplicate.
                            </p>
                          </div>
                        </div>
                        {/* <DealProgress /> */}
                      </div>
                    </StickyContainer>
                  ) : (
                    <div className='row'>
                      <div className='spinner-border'></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : !loader && shwEditForAdmin ? (
          <section className='main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-10 col-sm-12 dealPosterContainer'>
                  {!loader && result ? (
                    <StickyContainer>
                      <div className='row'>
                        <div className='post_deal col-md-12'>
                          <div className='postdeals_header'>
                            <h2>Update Product</h2>
                            {/* <p>
                              Help others save money by finding the lowest
                              price, best product,
                            </p> */}
                          </div>
                          <PostDealForm
                            editMode={true}
                            dealData={result}
                            dealCategories={dealCategories}
                            dealBrands={dealBrands}
                            dealStores={dealStores}
                            dealTags={dealTags}
                            {...this.props}
                            dealImages={dealImages}
                            key='edit'
                          />
                          <div className='deal_note'>
                            <p>
                              <strong>Helpful Product creating Tips : </strong>
                              Do a quick search on NAIFA to make sure this is
                              not a duplicate.
                            </p>
                          </div>
                        </div>
                        {/* <DealProgress /> */}
                      </div>
                    </StickyContainer>
                  ) : (
                    <div className='row'>
                      <div className='spinner-border'></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : !loader ? (
          <section>
            <div className='container-fluid'>
              <div className='row'>
                <div
                  className='col-md-10 col-sm-12 dealPosterContainer'
                  style={{ minHeight: "100vh" }}
                >
                  <div className='row'>
                    <div className=' col-md-12'>
                      <div className='postdeals_header p-5'>
                        Sorry you can't edit this product
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section>
            <div className='container-fluid'>
              <div className='row'>
                <div
                  className='col-md-10 col-sm-12 dealPosterContainer'
                  style={{ minHeight: "100vh" }}
                >
                  <div className='spinner-border'></div>
                </div>
              </div>
            </div>
          </section>
        )}
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loader: state.dealDetailsReducer.loader,
  result:
    state.dealDetailsReducer.result && state.dealDetailsReducer.result.deals,
  dealCategories:
    state.dealDetailsReducer.result && state.dealDetailsReducer.result.category,
  dealBrands:
    state.dealDetailsReducer.result && state.dealDetailsReducer.result.brands,
  dealStores:
    state.dealDetailsReducer.result && state.dealDetailsReducer.result.stores,
  dealTags:
    state.dealDetailsReducer.result && state.dealDetailsReducer.result.tags,
  dealImages:
    state.dealDetailsReducer.result && state.dealDetailsReducer.result.images,
  dealIUser:
    state.dealDetailsReducer.result && state.dealDetailsReducer.result.user,

  error: state.dealDetailsReducer.error,
  userInfo: state.profileReducer.result,
});
const mapDispatchToProps = (dispatch) => ({
  getDealView: (id) => dispatch(getDealViewAction(id)),
  clearInputProgress: () => dispatch(clearProgressAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDeal);

import axios from "axios";
import { url, ACCESS_TOKEN } from "../helpers/constants";
import history from "../helpers/history";

const token = localStorage.getItem("myToken");
const headers = {
  "Content-Type": "application/json",
  accessToken: ACCESS_TOKEN,
};

export const getDealsAction = (skip, limit) => (dispatch) => {
  let formData = new FormData();
  formData.append("skip", skip);
  formData.append("limit", limit);

  dispatch({
    type: "DEAL_FETCH_BEGINS",
    skip: skip + limit,
    limit: limit,
  });

  axios.post(url + "/api/deals", formData).then((res) => {
    // console.log("deals", res)
    if (res.status == 200) {
      // console.log("*** Inside  "+ res.data)
      dispatch({
        type: "DEAL_FETCH_SUCCESS",
        payload: res.data,
        hasMore: res.data.deals.length > 0,
      });
    } else {
      dispatch({
        type: "DEAL_FETCH_FAILURE",
      });
    }
  });
};
export const getHomeDealsAction = (skip, limit, filterData) => (dispatch) => {
  let formData = new FormData();
  formData.append("skip", skip);
  formData.append("limit", limit);

  formData.append("toggleExpired", filterData.toggleExpired);
  formData.append("filterBy", filterData.filterBy);

  dispatch({
    type: "DEAL_FETCH_BEGINS",
    skip: skip + limit,
    limit: limit,
  });

  axios.post(url + "/api/homePageDeals", formData).then((res) => {
    // console.log("deals", res)
    if (res.status == 200) {
      // console.log("*** Inside  "+ res.data)
      dispatch({
        type: "DEAL_FETCH_SUCCESS",
        payload: res.data,
        hasMore: res.data.deals.length > 0,
      });
    } else {
      dispatch({
        type: "DEAL_FETCH_FAILURE",
      });
    }
  });
};
export const getDealsByCatAction = (skip, limit, cat) => (dispatch) => {
  let formData = new FormData();
  formData.append("skip", skip);
  formData.append("limit", limit);
  formData.append("category", cat);

  dispatch({
    type: "DEAL_FETCH_BEGINS",
    skip: skip + limit,
    limit: limit,
  });

  axios.post(url + "/api/deals", formData).then((res) => {
    // console.log("deals", res)
    if (res.status == 200) {
      dispatch({
        type: "DEAL_FETCH_SUCCESS",
        payload: res.data,
        hasMore: res.data.deals.length > 0,
      });
    } else {
      dispatch({
        type: "DEAL_FETCH_FAILURE",
      });
    }
  });
};
export const getHomeDealsByCatAction =
  (skip, limit, cat, filterData) => (dispatch) => {
    let formData = new FormData();
    formData.append("skip", skip);
    formData.append("limit", limit);
    formData.append("category", cat);
    formData.append("toggleExpired", filterData.toggleExpired);
    formData.append("filterBy", filterData.filterBy);

    dispatch({
      type: "DEAL_FETCH_BEGINS",
      skip: skip + limit,
      limit: limit,
    });

    axios.post(url + "/api/homePageDeals", formData).then((res) => {
      // console.log("deals", res)
      if (res.status == 200) {
        dispatch({
          type: "DEAL_FETCH_SUCCESS",
          payload: res.data,
          hasMore: res.data.deals.length > 0,
        });
      } else {
        dispatch({
          type: "DEAL_FETCH_FAILURE",
        });
      }
    });
  };
export const sendLikeAction = (status, id) => (dispatch) => {
  // dispatch({
  //     type: 'DEAL_LIKE_DISLIKE_BEGINS',

  // })

  axios
    .post(
      url + "/api/like",
      { dealId: id, like: status },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("myToken")}` },
      }
    )
    .then((res) => {
      // console.log("like response", res)
      if (res.status == 200) {
        //
        if (status == 1) {
          dispatch({
            type: "DEAL_LIKE_SUCCESS",
            payload: {
              id: id,
              count: res.data.likecount - res.data.dislikecount,
            },
          });
        } else {
          dispatch({
            type: "DEAL_DISLIKE_SUCCESS",

            payload: {
              id: id,
              count: res.data.likecount - res.data.dislikecount,
            },
          });
        }
      } else {
        dispatch({
          type: "DEAL_LIKEDISLIKE_FAILURE",
          payload: { id, count: null },
        });
      }
    });
};

export const getDealDetailsAction = (args) => (dispatch) => {
  dispatch({
    type: "DEAL_DETAILS_FETCH_BEGINS",
  });

  axios.post(url + "/api/details", args).then((res) => {
    // console.log("dealDetails", res)
    if (res.status == 200) {
      dispatch({
        type: "DEAL_DETAILS_FETCH_SUCCESS",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "DEAL_DETAILS_FETCH_FAILURE",
      });
    }
  });
};
export const getDealViewAction = (id) => (dispatch) => {
  dispatch({
    type: "DEAL_DETAILS_FETCH_BEGINS",
  });

  axios
    .get(url + "/api/dealView/" + id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("myToken")}` },
    })
    .then((res) => {
      // console.log("dealDetails", res)
      if (res.status == 200) {
        dispatch({
          type: "DEAL_DETAILS_FETCH_SUCCESS",
          payload: res.data,
        });
      } else {
        dispatch({
          type: "DEAL_DETAILS_FETCH_FAILURE",
        });
      }
    });
};
export const searchDealAction = (data) => (dispatch) => {
  dispatch({
    type: "DEAL_SEARCH_BEGINS",
  });

  axios.post(url + "/api/search", data).then((res) => {
    if (res.data.response == "success") {
      dispatch({
        type: "DEAL_SEARCH_SUCCESS",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "DEAL_SEARCH_FAILURE",
      });
    }
  });
};
export const getPopularDealsAction = () => (dispatch) => {
  dispatch({
    type: "POPULAR_DEALS_FETCH_BEGINS",
  });
  axios.post(url + "/api/popularDeal").then((res) => {
    if (res.data.response == "success") {
      dispatch({
        type: "POPULAR_DEALS_FETCH_SUCCESS",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "POPULAR_DEALS_FETCH_FAILURE",
      });
    }
  });
};
export const clearDealsReducer = () => (dispatch) => {
  dispatch({
    type: "DEAL_FETCH_CLEAR",
  });
};
export const clearLikesReducerAction = () => (dispatch) => {
  dispatch({
    type: "DEAL_LIKE_DISLIKE_CLEAR",
  });
};

export const deleteDealsAction = (id, callBack) => (dispatch) => {
  dispatch({
    type: "DEAL_DELETE_BEGINS",
  });

  axios.post(url + "/api/deleteDeal", { id }).then((res) => {
    // console.log("deals", res)
    if (res.status == 200) {
      dispatch({
        type: "DEAL_DELETE_SUCCESS",
        payload: id,
      });
      if (callBack) {
        callBack();
      }
    } else {
      dispatch({
        type: "DEAL_DELETE_FAILURE",
      });
    }
  });
};

export default (
  state = {
    urlStatus: "",
    titleStatus: "",
    priceStatus: "",
    descriptionStatus: "",
    categoryStatus: "",
    brandStatus: "",
    storeStatus: "",
    labelStatus: "",
    imageStatus: "",
  },
  action
) => {
  switch (action.type) {
    case "CHANGE_INPUT_PROGRESS":
      let payLoad = action.payload;
      return {
        ...state,
        ...payLoad,
      };
    case "CLEAR_INPUT_PROGRESS":
      return {
        urlStatus: "",
        titleStatus: "",
        priceStatus: "",
        descriptionStatus: "",
        categoryStatus: "",
        brandStatus: "",
        storeStatus: "",
        labelStatus: "",

        imageStatus: "",
      };
    default:
      return state;
  }
};

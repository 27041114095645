
export default (state = {}, action) => {
  switch (action.type) {
    case 'DEAL_DELETE_BEGINS':
      return {
        loader:true,
        result: false,
        error:false
      }
    case 'DEAL_DELETE_SUCCESS':
      return {
        loader:false,
        result: true,
        error:false
      }
      case 'DEAL_DELETE_FAILURE':
        return {
          loader:false,
          result: false,
          error:true
        }  
    default:
      return state
  }
}
import axios from "axios";
import { url } from "../helpers/constants";

import { getUserAction } from "./auth/authAction";
export const getContributionsAction = () => (dispatch) => {
  dispatch({
    type: "MY_DEAL_FETCH_BEGINS",
  });
  let token = localStorage.getItem("myToken");
  if (token) {
    axios
      .post(
        url + "/api/myDeals",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.data.response == "success") {
          dispatch({
            type: "MY_DEAL_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "MY_DEAL_FAILURE",
          });
        }
      });
  }
};
export const getAllContributionsAction = (skip, limit) => (dispatch) => {
  let formData = new FormData();
  formData.append("skip", skip);
  formData.append("limit", limit);

  dispatch({
    type: "ALL_DEAL_FETCH_BEGINS",
    skip: skip + limit,
    limit: limit,
  });
  let token = localStorage.getItem("myToken");
  if (token) {
    axios
      .post(url + "/api/allDeals", formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.response == "success") {
          dispatch({
            type: "ALL_DEAL_SUCCESS",
            payload: res.data,
            hasMore: res.data.message.length > 0,
          });
        } else {
          dispatch({
            type: "ALL_DEAL_FAILURE",
          });
        }
      });
  }
};
export const getAllHuddleAction = (skip, limit) => (dispatch) => {
  let formData = new FormData();
  formData.append("skip", skip);
  formData.append("limit", limit);

  dispatch({
    type: "ALL_HUDDLE_FETCH_BEGINS",
    skip: skip + limit,
    limit: limit,
  });
  let token = localStorage.getItem("myToken");
  if (token) {
    axios
      .post(url + "/api/allForums", formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.response == "success") {
          dispatch({
            type: "ALL_HUDDLE_SUCCESS",
            payload: res.data,
            hasMore: res.data.message.length > 0,
          });
        } else {
          dispatch({
            type: "ALL_HUDDLE_FAILURE",
          });
        }
      });
  }
};
export const getVotesContributionsAction = () => (dispatch) => {
  dispatch({
    type: "MY_DEAL_FETCH_BEGINS",
  });
  let token = localStorage.getItem("myToken");
  if (token) {
    axios
      .post(
        url + "/api/myVotes",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.data.response == "success") {
          dispatch({
            type: "MY_DEAL_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "MY_DEAL_FAILURE",
          });
        }
      });
  }
};
export const getCommentsContributionsAction = () => (dispatch) => {
  dispatch({
    type: "MY_DEAL_FETCH_BEGINS",
  });
  let token = localStorage.getItem("myToken");
  if (token) {
    axios
      .post(
        url + "/api/myComments",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.data.response == "success") {
          dispatch({
            type: "MY_DEAL_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "MY_DEAL_FAILURE",
          });
        }
      });
  }
};
export const getUsersListAction = () => (dispatch) => {
  dispatch({
    type: "USEL_LIST_FETCH_BEGINS",
  });
  let token = localStorage.getItem("myToken");
  if (token) {
    axios
      .post(
        url + "/api/userList",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        // console.log('user list', res)
        if (res.data.response == "success") {
          dispatch({
            type: "USEL_LIST_FETCH_SUCCESS",
            payload: res.data.message,
          });
        } else {
          dispatch({
            type: "USEL_LIST_FETCH_FAILURE",
          });
        }
      });
  }
};
export const saveUserAction = (data) => (dispatch) => {
  // let formdata =  new FormData();
  // formdata.append('dealUrl',data.firstName);
  // formdata.append('dealTitle',data.dealTitle);
  // formdata.append('dealPrice',data.dealPrice);
  // formdata.append('dealDescription',data.dealDescription);
  // for (let i = 0; i < data.dealStores.length; i+=1) {
  //     formdata.append('dealStores[]',data.dealStores[i]['id']);
  // }
  // for (let i = 0; i < data.dealCategories.length; i+=1) {
  //     formdata.append('dealCategories[]',data.dealCategories[i]['id']);
  // }
  // for (let i = 0; i < data.dealBrands.length; i+=1) {
  //     formdata.append('dealBrands[]',data.dealBrands[i]['id']);
  // }

  // for (let i = 0; i < data.dealImage.length; i+=1) {
  //     formdata.append('image[]',data.dealImage[i]);
  // }
  // for (let i = 0; i < data.dealUrlImages.length; i+=1) {
  //     formdata.append('dealUrlImages[]',data.dealUrlImages[i].url);
  // }
  // for (let i = 0; i < data.dealImage.length; i+=1) {
  //     formdata.append('dealImage[]',data.dealImage[i]);
  // }

  dispatch({
    type: "UPDATE_USER_BEGINS",
  });

  axios
    .post(url + "/api/editProfile", data, {
      headers: { Authorization: `Bearer ${localStorage.getItem("myToken")}` },
    })
    .then((res) => {
      if (res.data.response == "success") {
        dispatch({
          type: "UPDATE_USER_SUCCESS",
        });

        setTimeout(() => {
          dispatch(getUsersListAction());
          // dispatch(getUserAction())
        });
      } else {
        dispatch({
          type: "UPDATE_USER_FAILURE",
        });
      }
    });
};
export const saveAccountBasicsAction = (data) => (dispatch) => {
  dispatch({
    type: "UPDATE_USER_BEGINS",
  });

  axios
    .post(url + "/api/editProfile", data, {
      headers: { Authorization: `Bearer ${localStorage.getItem("myToken")}` },
    })
    .then((res) => {
      if (res.data.response == "success") {
        dispatch({
          type: "UPDATE_USER_SUCCESS",
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        dispatch({
          type: "UPDATE_USER_FAILURE",
        });
      }
    });
};

export const getUserProfileAction = (id) => (dispatch) => {
  let token = localStorage.getItem("myToken");
  if (token) {
    dispatch({
      type: "GET_USER_DETAILS_BEGINS",
    });

    axios
      .post(
        url + "/api/userProfile",
        { id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        // console.log('profileresponse', res)
        if (res.data.response == "success") {
          dispatch({
            type: "GET_USER_DETAILS_SUCCESS",
            payload: res.data,
          });
        } else {
          localStorage.clear();

          dispatch({
            type: "GET_USER_DETAILS_FAILURE",
            payload: res.data,
          });
        }
      });
  }
};
export const getReputationAction = (userId) => (dispatch) => {
  let token = localStorage.getItem("myToken");
  if (token) {
    dispatch({
      type: "GET_REPUTATION_BEGINS",
    });

    axios
      .post(
        url + "/api/getReputation",
        { userId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.data.response == "success") {
          dispatch({
            type: "GET_REPUTATION_SUCCESS",
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: "GET_REPUTATION_FAILURE",
            payload: res.data.message,
          });
        }
      });
  }
};

export const clearMyDealsReducerAction = () => (dispatch) => {
  dispatch({ type: "CLEAR_MYDEALS_RESULTS" });
};
export const clearAllDealsAction = () => (dispatch) => {
  dispatch({ type: "CLEAR_ALL_DEALS_RESULTS" });
};
export const clearUserUpdateMsg = () => (dispatch) => {
  dispatch({ type: "UPDATE_USER_CLEAR" });
};

import React, { useState } from 'react';



const AddTag = (props) => { 
  const [formData, setFormData] = useState({name:'',description:''});
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(false);
  const handleChange = (e) =>{
    setFormData({
      ...formData,
        [e.target.name]:e.target.value    
    })

  }
  const saveTag = (e) =>{
    setSubmitted(true)
    setErrors(false)
    e.preventDefault()
      if(formData.name && formData.description){
      props.saveTag(formData)
      setTimeout(() => {
        setSubmitted(false)
        setFormData({name:'', description:''})
      }, 1000);
      }else{
        setErrors(true)
      }

  }

 

  return (
    <div className="account_basic_settings">
<div className="row  mb10">
    <div className="col-md-5">
       <label>Name</label>
    </div>
    <div className="col-md-7">
    <input 
                   
                   // ref={input => input && input.focus()}
                   type="text" 
                   name="name" className="textinput" placeholder="Tag..." id="name" value={formData.name} onChange={(e)=>handleChange(e)}
                   />
                   {!formData.name && submitted&&<p className="help-block">This field is required</p>}
 </div>
 </div>
<div className="row  mb10">
    <div className="col-md-5">
       <label>Description</label>
    </div>
    <div className="col-md-7">
    <textarea value={formData.description} className="textinput"  onChange={(e)=>handleChange(e)} name="description" id="description" cols="30" rows="2">{formData.description}</textarea>
    {!formData.description && submitted&&<p className="help-block">This field is required</p>}
 </div>
 </div>

 <div className="row">
   <div className="col-md-12">

   <input type="submit" name="" onClick={(e)=>saveTag(e)} value={submitted&&!errors?"Please wait":"Add"} className="btn btn-primary pull-right" />
          
   </div>
 </div>
    </div>
 
  );
}
export default AddTag;
export const imagePath = `${process.env.REACT_APP_BASE_PATH}/backend/public/upload/dealimages`;
export const avatarPath = `${process.env.REACT_APP_BASE_PATH}/backend/public/upload/avatars`;
export const url = `${process.env.REACT_APP_BASE_PATH}/backend/`;
export const ACCESS_TOKEN = `${process.env.REACT_APP_ACCESS_TOKEN}`;

//like or dislike button
export const likeStyle = { color: "#BBDA90", cursor: "pointer" };
export const dislikeStyle = { color: "#F1ACAD", cursor: "pointer" };

export const dealEditablePeriod = 7; //days
export const perPage = 20; //nos

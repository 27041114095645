import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  clearAllDealsAction,
  getAllContributionsAction,
} from "../actions/profileAction";
import ReduxLazyScroll from "redux-lazy-scroll";
import moment from "moment";
import axios from "axios";
import { url } from "../helpers/constants";
import { deleteDealsAction } from "../actions/dealsAction";

class AllDeals extends Component {
  statusMessage = {
    message: "",
  };

  constructor(props) {
    super(props);
    this.loadPosts = this.loadPosts.bind(this);
    this.deleteStore = this.deleteItem.bind(this);
    this.convertUTCToLocal = this.convertUTCToLocal.bind(this);
    // this.deleteItem = this.deleteItem.bind(this)
  }
  convertUTCToLocal = (utcDt, utcDtFormat) => {
    var toDt = moment.utc(utcDt, utcDtFormat).toDate();
    return moment(toDt).format("DD-MM-YYYY hh:mm:ss A");
  };
  loadPosts() {
    const { skip, limit } = this.props;
    this.props.getAllContributions(skip, limit);
  }

  deleteItem = (id) => {
    this.props.deleteDeal(id);
  };

  componentDidMount() {
    // this.props.getAllContributions(0, 20);
  }
  componentWillUnmount() {
    this.props.clearAllDeals();
  }
  render() {
    const { mydealLoader, mydealResult, mydealError, hasMore } = this.props;
    return (
      <div>
        <div>
          {this.statusMessage.message && (
            <h3 className='info'> {this.statusMessage.message} </h3>
          )}
        </div>

        <div className='container posts-lazy-scroll'>
          <ReduxLazyScroll
            initialLoad={false}
            isFetching={mydealLoader}
            errorMessage={mydealError}
            loadMore={this.loadPosts}
            hasMore={hasMore}
          >
            <div className='contribution_list_header'></div>
            <div className='contributionsList'>
              <ul>{this.renderDeals()}</ul>
            </div>
          </ReduxLazyScroll>
        </div>
        {mydealLoader && <div className='spinner-border'> </div>}
        {!mydealLoader && mydealResult && mydealResult.length <= 0 && (
          <div>No Products</div>
        )}
      </div>

      ////
    );
  }

  renderDeals() {
    const { mydealResult } = this.props;

    return mydealResult.map((deal, idx) => {
      let statusClass = "active";
      if (deal.del_active !== 1) {
        statusClass = "expired";
      }
      return (
        <li key={idx} className={statusClass}>
          <Link to={`/deal/${deal.id}`} className='title'>
            {deal.del_title}|{" "}
            {deal.del_price ? "$" + deal.del_price.toFixed(2) : ""}
          </Link>
          <div className='store'>
            Type:&nbsp;
            {deal.dealstores &&
              deal.dealstores.map((str, idx) => {
                return str.del_store != null ? str.del_store.str_name : "";
              })}
          </div>
          <div className='dateline'>
            Created at {this.convertUTCToLocal(deal.created_at)} by{" "}
            {deal.user && deal.user.firstName} &nbsp;&nbsp;{" "}
            <Link title='edit' to={`/edit-deal/${deal.id}`}>
              <i className='fa fa-pencil'></i>
            </Link>
            &nbsp;&nbsp;{" "}
            <a
              href='#'
              title='Delete'
              onClick={(e) => {
                if (
                  window.confirm(
                    "Are you sure you wish to delete this products?"
                  )
                )
                  this.deleteItem(deal.id);
              }}
            >
              <i className='fa fa-trash'></i>
            </a>{" "}
          </div>
          <div className='bottom'>
            {/* <span className="icon">
               <i className="fa fa-thumbs-up" aria-hidden="true"></i>
             
               </span>&nbsp; 
                {(deal.del_count_like - deal.del_count_dislike)} */}

            {/* <span className="icon"><i className="fa fa-commenting" aria-hidden="true"></i></span>
               22 */}
            {/* <span className="expiredBadge">Expired</span> */}
          </div>
          <div id='accordion'>
            <div className='card'>
              <div className='card-header'>
                <a
                  className='card-link collapsed'
                  data-toggle='collapse'
                  href={`#collapse${idx}`}
                >
                  Description
                </a>
              </div>
              <div
                id={`collapse${idx}`}
                className='collapse'
                data-parent='#accordion'
              >
                <div
                  className='card-body'
                  dangerouslySetInnerHTML={{ __html: deal.del_descr }}
                ></div>
              </div>
            </div>
          </div>
        </li>
      );
    });
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.profileReducer.result,
  mydealLoader: state.allDealsReducer.loader,
  mydealResult: state.allDealsReducer.result,
  mydealCount: state.allDealsReducer.count,
  mydealError: state.allDealsReducer.error,
  hasMore: state.allDealsReducer.hasMore,
  skip: state.allDealsReducer.skip,
  limit: state.allDealsReducer.limit,
});
const mapDispatchToProps = (dispatch) => ({
  getAllContributions: (skip, limit) =>
    dispatch(getAllContributionsAction(skip, limit)),
  clearAllDeals: () => dispatch(clearAllDealsAction()),
  deleteDeal: (id) => dispatch(deleteDealsAction(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllDeals);

import React, { useState } from "react";

const AddStore = (props) => {
  // const {

  // } = props;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const saveStore = (e) => {
    setSubmitted(true);
    setErrors(false);
    e.preventDefault();
    if (formData.name) {
      props.saveStore(formData);

      setTimeout(() => {
        setSubmitted(false);
        setFormData({ name: "", description: "" });
      }, 1000);
    } else {
      setErrors(true);
    }
  };

  const [formData, setFormData] = useState({ name: "", description: "" });
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(false);

  return (
    <div className='account_basic_settings'>
      <div className='row mb10'>
        <div className='col-md-5'>
          <label>Name</label>
        </div>
        <div className='col-md-7'>
          <input
            type='text'
            name='name'
            className='textinput'
            placeholder='Type...'
            id='name'
            value={formData.name}
            onChange={(e) => handleChange(e)}
          />
          {!formData.name && submitted && (
            <p className='help-block'>This field is required</p>
          )}
        </div>
      </div>

      <div className='row mb10'>
        <div className='col-md-5'>
          <label>Description</label>
        </div>
        <div className='col-md-7'>
          <textarea
            value={formData.description}
            className='textinput'
            onChange={(e) => handleChange(e)}
            name='description'
            id='description'
            cols='30'
            rows='2'
          >
            {formData.description}
          </textarea>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <input
            type='submit'
            name=''
            onClick={(e) => saveStore(e)}
            value={submitted && !errors ? "Please wait" : "Save"}
            className='btn btn-primary pull-right'
          />
        </div>
      </div>
    </div>
  );
};

export default AddStore;

import axios from "axios";
import { url } from "../helpers/constants";

const token = localStorage.getItem("myToken");

export const postDealAction = (data) => (dispatch) => {
  dispatch({
    type: "DEAL_FETCH_CLEAR",
  });

  //console.log("post data", data)
  let formdata = new FormData();
  formdata.append("isOnline", data.isOnline);
  formdata.append("isFeatured", data.isFeatured);
  formdata.append("del_hide_stricken_price", data.hideOriginalPrice);
  formdata.append("dealUrl", data.dealUrl);
  formdata.append("dealTitle", data.dealTitle);
  formdata.append("dealPrice", data.dealPrice);
  formdata.append("del_price2", data.del_price2);
  formdata.append("stricken_price", data.stricken_price);
  formdata.append("dealDescription", data.dealDescription);
  formdata.append("dealStatus", data.dealStatus);
  for (let i = 0; i < data.dealStores.length; i += 1) {
    formdata.append("dealStores[]", data.dealStores[i]["id"]);
  }
  for (let i = 0; i < data.dealCategories.length; i += 1) {
    formdata.append("dealCategories[]", data.dealCategories[i]["id"]);
  }
  for (let i = 0; i < data.dealBrands.length; i += 1) {
    formdata.append("dealBrands[]", data.dealBrands[i]["id"]);
  }
  for (let i = 0; i < data.dealTags.length; i += 1) {
    formdata.append("dealTags[]", data.dealTags[i]["id"]);
  }

  if (data.id) {
    formdata.append("id", data.id);
  }

  if (data.dealImage) {
    for (let i = 0; i < data.dealImage.length; i += 1) {
      formdata.append("image[]", data.dealImage[i]);
    }
  }
  if (data.dealUrlImages) {
    for (let i = 0; i < data.dealUrlImages.length; i += 1) {
      formdata.append("dealUrlImages[]", data.dealUrlImages[i].url);
    }
  }
  if (data.primaryImage) {
    formdata.append("primaryImage", data.primaryImage);
  }

  dispatch({
    type: "POST_DEAL_BEGINS",
  });

  if (data.id) {
    axios
      .post(url + "/api/editDeal", formdata, {
        headers: { Authorization: `Bearer ${localStorage.getItem("myToken")}` },
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: "UPDATE_DEAL_SUCCESS",
          });
        } else {
          dispatch({
            type: "UPDATE_DEAL_FAILURE",
          });
        }
      });
  } else {
    axios
      .post(url + "/api/postdeal", formdata, {
        headers: { Authorization: `Bearer ${localStorage.getItem("myToken")}` },
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: "POST_DEAL_SUCCESS",
          });
        } else {
          dispatch({
            type: "POST_DEAL_FAILURE",
          });
        }
      });
  }
};
export const getImagesFromUrlAction = (linkUrl) => (dispatch) => {
  dispatch({
    type: "POST_DEAL_URL_BEGINS",
  });

  // console.log(url)
  axios
    .post(
      url + "/api/getImage",
      { url: linkUrl },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("myToken")}` },
      }
    )
    .then((res) => {
      //  console.log("respo",res)

      if (res.status == 200) {
        dispatch({
          type: "POST_DEAL_URL_SUCCESS",
          payload: res.data,
        });
      } else {
        dispatch({
          type: "POST_DEAL_URL_FAILURE",
        });
      }
    });
};
export const removeDealImageAction = (id) => (dispatch) => {
  // console.log("asasaasasa",id)

  axios
    .post(
      url + "/api/deleteDealImage",
      { id },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("myToken")}` },
      }
    )
    .then((res) => {
      // console.log("respo",res)
      // if (res.status ==200) {
      //     dispatch({
      //         type: 'POST_DEAL_URL_SUCCESS',
      //         payload:res.data.image
      //     })
      // } else {
      //     dispatch({
      //         type: 'POST_DEAL_URL_FAILURE'
      //     })
      // }
    });
};

export const clearAction = () => (dispatch) => {
  dispatch({
    type: "POST_DEAL_CLEAR",
  });
};
export const clearUrlImagesAction = () => (dispatch) => {
  dispatch({
    type: "POST_DEAL_URL_CLEAR",
  });
};
export const sendProgressAction = (status) => (dispatch) => {
  dispatch({
    type: "CHANGE_INPUT_PROGRESS",
    payload: status,
  });
};
export const clearProgressAction = () => (dispatch) => {
  dispatch({
    type: "CLEAR_INPUT_PROGRESS",
  });
};

export const deleteDeal = (id) => (dispatch) => {
  // console.log("asasaasasa",id)
  axios
    .post(
      url + "/api/deleteDeal",
      { id },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("myToken")}` },
      }
    )
    .then((res) => {
      console.log("respo", res);
      if (res.data.response === "success") {
        dispatch({
          type: "DEAL_DELETE_SUCCESS",
          payload: id,
        });
        setTimeout(() => {
          //   dispatch(clearMailsAction())
        }, 2000);
      } else {
        dispatch({
          type: "DEAL_ACTION_FAILURE",
          payload: res.data.message,
        });
        setTimeout(() => {
          // dispatch(clearMailsAction())
        }, 2000);
      }
    });
};

import axios from 'axios';
import {url} from '../helpers/constants';

export const getBrandsAction = (isShowAll) => dispatch => {   
    axios.post(url+'/api/brands',isShowAll, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}})  
    
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: 'BRANDS_FETCH_SUCCESS',
                    payload: res.data.brands
                })
            } else {
                dispatch({
                    type: 'BRANDS_FETCH_FAILURE'
                })

            }
        })
}


export const getCategoriesAction = (isShowAll) => dispatch => {    
    axios.post(url+'/api/category',isShowAll, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    }
    )
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: 'CATEGORY_FETCH_SUCCESS',
                    payload: res.data.category

                })
            } else {
                dispatch({
                    type: 'CATEGORY_FETCH_FAILURE'
                })
            }
        })
}
export const getPopularCatsAction = () => dispatch => {   
    dispatch({
        type: 'POPULAR_CATEGORY_FETCH_BEGINS'
     
    }) 
    axios.post(url+'/api/category',{}, { //API to be changed
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    }
    )
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: 'POPULAR_CATEGORY_FETCH_SUCCESS',
                    payload: res.data.category

                })
            } else {
                dispatch({
                    type: 'POPULAR_CATEGORY_FETCH_FAILURE'
                })
            }
        })
}

export const getStoresAction = (isShowAll) => dispatch => {
    
    axios.post(url+'/api/store',isShowAll, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: 'STORES_FETCH_SUCCESS',
                    payload: res.data.store

                })
            } else {
                dispatch({
                    type: 'STORES_FETCH_FAILURE'
                })
            }
        })
}
export const getTagsAction = (isShowAll) => dispatch => {
    
    axios.post(url+'/api/tags',isShowAll, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: 'TAGS_FETCH_SUCCESS',
                    payload: res.data.tags

                })
            } else {
                dispatch({
                    type: 'TAGS_FETCH_FAILURE'
                })
            }
        })
}


//Add Store

export const addStoresAction = (formData) => dispatch => {
window.scrollTo(0,0)
    dispatch({
        type: 'STORE_ADD_BEGINS',
       

    })
    
    axios.post(url+'/api/addStore',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
                let isShowAll = {
                    showAll:1
                }

                dispatch(getStoresAction(isShowAll))
                dispatch({
                    type: 'STORE_ADD_SUCCESS',
                    payload: res.data.message

                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            } else {
                dispatch({
                    type: 'STORE_ADD_FAILURE',
                    payload: res.data.message

                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            }
        })

     
}
export const updateStoreAction = (formData) => dispatch => {
    window.scrollTo(0,0)
    dispatch({
        type: 'STORE_ADD_BEGINS',
       

    })
    
    axios.post(url+'/api/updateStore',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
                let isShowAll = {
                    showAll:1
                }

                dispatch(getStoresAction(isShowAll))
                dispatch({
                    type: 'STORE_UPDATE_SUCCESS',
                    payload: res.data.message

                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            } else {
                dispatch({
                    type: 'STORE_ADD_FAILURE',
                    payload: res.data.message
                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            }
        })

     
}
export const deleteMasterAction = (id,type) => dispatch => {
    window.scrollTo(0,0)
    
    axios.post(url+'/api/deleteMasters',{id,type}, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
                let isShowAll = {
                    showAll:1
                }
                switch (type) {
                    case 'str':
                        dispatch(getStoresAction(isShowAll)) 
                        break;
                    case 'brd':
                         dispatch(getBrandsAction(isShowAll)) 
                        break;
                    case 'cat':
                        dispatch(getCategoriesAction(isShowAll))
                        break;
                    case 'tag':
                        dispatch(getTagsAction(isShowAll))
                        break;
                
                    default:
                        break;
                }

               
              
                dispatch({
                    type: 'MASTER_DEL_SUCCESS',
                    payload: res.data.message

                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            } else {
                dispatch({
                    type: 'MASTER_DEL_FAILURE',
                    payload: res.data.message

                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            }
        })

      
}
//Add Brand

export const addBrandsAction = (formData) => dispatch => {
    window.scrollTo(0,0)
    dispatch({
        type: 'BRAND_ADD_BEGINS',
       

    })
    
    axios.post(url+'/api/addBrand',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
                let isShowAll = {
                    showAll:1
                }

                dispatch(getBrandsAction(isShowAll))
                dispatch({
                    type: 'BRAND_ADD_SUCCESS',
                    payload: res.data.message

                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            } else {
                dispatch({
                    type: 'BRAND_ADD_FAILURE',
                    payload: res.data.message
                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            }
        })

      
}
export const updateBrandsAction = (formData) => dispatch => {
    window.scrollTo(0,0)
    dispatch({
        type: 'BRAND_ADD_BEGINS',
       

    })
    
    axios.post(url+'/api/updateBrand',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
let isShowAll={
    showAll:1
}
                dispatch(getBrandsAction(isShowAll))
                dispatch({
                    type: 'BRAND_UPDATE_SUCCESS',
                    payload: res.data.message

                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            } else {
                dispatch({
                    type: 'BRANDS_UPDATE_FAILURE',
                    payload: res.data.message
                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            }
        })

     
}
//Add Tags

export const addTagsAction = (formData) => dispatch => {
    window.scrollTo(0,0)
    dispatch({
        type: 'TAGS_ADD_BEGINS',
       

    })
    
    axios.post(url+'/api/addTag',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
                let isShowAll = {
                    showAll:1
                }

                dispatch(getTagsAction(isShowAll))
                dispatch({
                    type: 'TAGS_ADD_SUCCESS',
                    payload: res.data.message

                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            } else {
                dispatch({
                    type: 'TAGS_ADD_FAILURE',
                    payload: res.data.message
                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            }
        })

      
}
export const updateTagsAction = (formData) => dispatch => {
    window.scrollTo(0,0)
    dispatch({
        type: 'TAGS_ADD_BEGINS',
       

    })
    
    axios.post(url+'/api/updateTag',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
let isShowAll={
    showAll:1
}
                dispatch(getTagsAction(isShowAll))
                dispatch({
                    type: 'TAGS_UPDATE_SUCCESS',
                    payload: res.data.message

                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            } else {
                dispatch({
                    type: 'TAGS_UPDATE_FAILURE',
                    payload: res.data.message
                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            }
        })

     
}
//Add Category

export const addCategoryAction = (formData) => dispatch => {
    window.scrollTo(0,0)
    dispatch({
        type: 'CATEGORY_ADD_BEGINS',
       

    })
    
    axios.post(url+'/api/addCategory',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
                let isShowAll = {
                    showAll:1
                }
                dispatch(getCategoriesAction(isShowAll))
                dispatch({
                    type: 'CATEGORY_ADD_SUCCESS',
                    payload: res.data.message

                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            } else {
                dispatch({
                    type: 'CATEGORY_ADD_FAILURE',
                    payload: res.data.message
                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            }
        })

          

}
export const updateCategoryAction = (formData) => dispatch => {
    window.scrollTo(0,0)
    dispatch({
        type: 'CATEGORY_ADD_BEGINS',
       

    })
    
    axios.post(url+'/api/editCategory',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {

                let isShowAll={
                    showAll:1
                }

                dispatch(getCategoriesAction(isShowAll))
                dispatch({
                    type: 'CATEGORY_UPDATE_SUCCESS',
                    payload: res.data.message

                })
                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            } else {
                dispatch({
                    type: 'CATEGORY_UPDATE_FAILURE',
                    payload: res.data.message
                })

                setTimeout(() => {

                    dispatch({
                        type: 'MASTER_ACTION_CLEAR'
                      
                  })
                    
                }, 3000);
            }
        })

   
}

export const clearMasterAddMsg = () => dispatch => {

    dispatch({
                   type: 'MASTER_ACTION_CLEAR'
                 

             })
    
 
}

